<template>
	<div class="content">
		<section class="head-section">
			<div class="container cols-2">
				<div class="images-col"
				data-aos="opacity-animation" 
				data-aos-duration="2000">
					<picture class="full-image" >
						<source srcset="../assets/images/balkon.webp" type="image/webp">
						<img src="../assets/images/balkon.jpg" alt="Lember Flowers" width="372" height="494">
					</picture>
				</div>
				<div class="content">
					<h1 class="title">
						<span
						data-aos="fade-right"
					data-aos-duration="1000">Балконне</span> 
					<span
					data-aos="fade-left"
					data-aos-duration="1000" class="left-move">озеленення</span></h1>
				</div>
			</div>
		</section>
		<section class="list-section">
			<div class="container">
				<h2 class="title">Процес реалізації</h2>
				<div class="list-wrap">
					<ul class="list conunts">
						<li v-for="item in list" :key="item" class="count-item">
							<h4>{{item.title}}</h4>
							<p v-if="item.descr.length">{{item.descr}}</p>
						</li>
					</ul>
					<router-link to="#consultation" class="btn">ХОЧУ КОНСУЛЬТАЦІЮ</router-link>
				</div>
			</div>
		</section>
		<section class="count-item-section">
			<div class="container">
				<div class="flower-bg"></div>
				<div class="conunts">
					<div class="count-item">
						<p>Як привернути увагу клієнтів, коли поруч ще багато закладів – ваших конкурентів?</p>
					</div>
					<div class="count-item">
						<p>Як створити затишок у своїй оселі?</p>
					</div>
					<div class="count-item">
						<p>Як підібрати правильно квіти для розміщення на балконі й витратити на це мінімум часу?</p>
					</div>
				</div>
			</div>
		</section>
		<section class="bg-section">
			<h2 class="title">Не знаєте як вирішити ці запитання – зверніться до професіоналів</h2>
			<div class="container cols-2">
				<div class="images-col">
					<picture class="full-image" >
						<source srcset="../assets/images/balcon-2.webp" type="image/webp">
						<img src="../assets/images/balcon-2.jpg" alt="Lember Flowers" width="290" height="180">
					</picture>
					<picture class="full-image" >
						<source srcset="../assets/images/balcon-3.webp" type="image/webp">
						<img src="../assets/images/balcon-3.jpg" alt="Lember Flowers" width="288" height="460">
					</picture>
				</div>
				<div class="content">
					<h3>МИ ЄДИНА КОМПАНІЯ У ЛЬВОВІ, ЯКА КОМПЛЕКСНО ЗАЙМАЄТЬСЯ ОЗЕЛЕНЕННЯМ БАЛКОНІВ І ТЕРАС.</h3>
					<p>Беремо на себе всі процеси "під ключ": розробка проєкту фітодизайнером, посадка та вирощення квітів, доставка на об'єкт, облаштування інтер'єру та екстер'єру закладу або приватного помешкання.</p>
				</div>
			</div>
		</section>
		<section class="section-gallery">
			<div class="container cols-2">
				<div>
					<p>З озелененням від команди Lemberg Flowers люди у своїх будинках створюють територію затишку і відпочинку, а ресторани та готелі приваблюють більше клієнтів.</p>
				</div>
				<div>
					<h2>Люди йдуть туди, де гарно та атмосферно.</h2>
				</div>
			</div>
			<div class="container">
				<div class="gallery">
					<div v-for="(img, index) in gallery"
					:key="index" :class="'pic'"
					@click="() => showGalleryImg(index)">
						<picture>
							<source :srcset="typeof img === 'string' ? img : img.webp" type="image/webp">
							<img :src="typeof img === 'string' ? img : img.thumbnail" alt="Lember Flowers" :ref="setImagemRef">
						</picture>
					</div>
					<vue-easy-lightbox
					:visible="visibleImg"
					:imgs="gallery"
					:index="indexGallery"
					:moveDisabled = "moveDisabled"
					:scrollDisabled = "scrollDisabled"
					:swipeTolerance = "swipeTolerance"
					:escDisabled="escDisabled"
					:loop="loop"
					@hide="handleGalleryHide">
						
					</vue-easy-lightbox>
				</div>
			</div>
		</section>
		<section>
			<div class="container">
				<v-form/>
			</div>
		</section>
		<section>
			<v-contacts/>
		</section>
	</div>
</template>

<style scoped lang="scss">
	@import "../assets/scss/components/balcony-page.scss"
</style>

<script>
	import ContactForm from '@/components/ContactForm.vue'
	import Contacts from '@/components/Contacts.vue'
	import VueEasyLightbox from 'vue-easy-lightbox'

	import { ref, onMounted, onBeforeUpdate } from 'vue'


	export default {
		setup() {
			let imageRefs = ref([])
			const setImagemRef = el => {
				if (el) {
					imageRefs.value.push(el)
				}
			}
		onMounted(() => {
			imageRefs.value.forEach(function(entry) {
				entry.width = entry.clientWidth
				entry.height = entry.clientHeight
			});
		})
		onBeforeUpdate(() => {
			imageRefs.value = []

		})
			return {
				setImagemRef
			}
		},
		components: {
			VueEasyLightbox,
			'v-contacts': Contacts,
			'v-form': ContactForm
		},
		data(){
			return {
				list:[
					{
						title:'З 1-го лютого до 15 квітня',
						descr: 'Чекаємо Ваші кашпо, горщики, ящики у нашому садовому центрі'
					},
					{
						title:'Вибір рослин',
						descr: 'У нас широкий асортимент для озеленення балконів та терас'
					},
					{
						title:'Оформлення замовлення',
						descr: ''
					},
					{
						title:'Вирощування в парнику',
						descr: 'У нас створені всі умови для швидкого та правильного вирощування Ваших рослин'
					},
					{
						title:'До 10 травня (по готовності)',
						descr: 'Забираєте Ваші готові замовлення'
					}
				],
				visibleImg: false,
				indexGallery: 0,
				moveDisabled:true,
				scrollDisabled : true,
				swipeTolerance : 50,
				escDisabled : true,
				loop: true,
				gallery: [
				{
					src: require('../assets/images/balcon-gallery/image-1.jpg'),
					webp: require('../assets/images/balcon-gallery/small-1.webp'),
					thumbnail: require('../assets/images/balcon-gallery/small-1.jpg')
				},
				{
					src: require('../assets/images/balcon-gallery/image-2.jpg'),
					webp: require('../assets/images/balcon-gallery/small-2.webp'),
					thumbnail: require('../assets/images/balcon-gallery/small-2.jpg') 
				},
				{
					src: require('../assets/images/balcon-gallery/image-3.jpg'),
					webp: require('../assets/images/balcon-gallery/small-3.webp'),
					thumbnail: require('../assets/images/balcon-gallery/small-3.jpg') 
				},
				{
					src: require('../assets/images/balcon-gallery/image-4.jpg'),
					webp: require('../assets/images/balcon-gallery/small-4.webp'),
					thumbnail: require('../assets/images/balcon-gallery/small-4.jpg') 
				},
				{
					src: require('../assets/images/balcon-gallery/image-5.jpg'),
					webp: require('../assets/images/balcon-gallery/small-5.webp'),
					thumbnail: require('../assets/images/balcon-gallery/small-5.jpg') 
				}
			]
			} 
		},
		methods: {
			showGalleryImg(index) {
				this.indexGallery = index
				this.visibleImg = true
			},
			handleGalleryHide() {
				this.visibleImg = false
			},
			anchorHashCheck(hash) {
				let url
				setTimeout(function(){
					url = hash
					if (url) {
						let el = document.getElementById(hash)
						if (el) {
							el.scrollIntoView({behavior: "smooth"})
						}
					}
					},300)
				if(this.$route.hash !== "") {
					let elem = document.querySelector(`${this.$route.hash}`)

					window.setTimeout(function(){
						elem.scrollIntoView({behavior: "smooth"})
					}, 500);
				}
			}
		}
	}
</script>
